<template>
  <b-modal v-model="active" size="lg" title="Vin Search Patterns" no-enforce-focus>
    <b-row>
      <b-col>
        <h5>Insert parttern</h5>
        <b-form-group>
          <label>Brand</label>
          <b-select v-model="model.link_brand">
            <b-select-option :value="null">Select</b-select-option>
            <b-select-option v-for="item of brands" :value="item.value">
              {{ item.name }}
            </b-select-option>
          </b-select>
        </b-form-group>
        <b-form-group>
          <label>Pattern</label>
          <b-form-input v-model="model.pattern"/>
        </b-form-group>
        <h6>Test</h6>
        <b-form-group>
          <label>Insert VIN</label>
          <b-form-input v-model="vin"/>
          <b-form-valid-feedback force-show>Patternt is {{ isValid ? 'Valid' : 'InValid' }}</b-form-valid-feedback>
        </b-form-group>
        <div class="text-right">
          <b-button @click="confimation('save')">Save</b-button>
        </div>
      </b-col>
      <b-col>
        <b-table small bordered hover :fields="fields" :items="list">
          <template #cell(options)="{item}">
            <i class="material-icons" @click="confimation('delete',item)">clear</i>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {compare} from 'bcryptjs'
import {get, post} from "@/services/api";

export default {
  name: "VinSearchPatterns",
  data: () => ({
    active: false,
    brands: [
      {name: 'Audi', value: 'audi_parts'},
      {name: 'Seat', value: 'seat_parts'},
      {name: 'Skoda', value: 'skoda_parts'},
      {name: 'Volkswagen', value: 'vw_parts'},
      {name: 'Volkswagen Commercial Vehicles', value: 'vn_parts'},
    ],
    model: {
      pattern: null,
      link_brand: null
    },
    vin: null,
    pass: '$2a$12$.fHiwenxREpm8xqx5xMLR.rmX4/YojQk31mLS8ErtGTTqy1yPSOmy',
    list: [],
    fields: [
      'pattern',
      'link_brand',
      'options'
    ]
  }),
  computed: {
    isValid() {
      const regexp = new RegExp(this.model.pattern, 'g');
      return regexp.test(this.vin)
    }
  },
  methods: {
    load() {
      get(`vin-search-patterns`, null, true)
        .then(({data}) => {
          this.list = data;
        })
    },
    save() {
      post(`vin-search-patterns`, this.model, true)
        .then(() => {
          this.load();
        })
    },
    delete(item) {
      post(`vin-search-patterns/delete`, {_id: item._id}, true)
        .then(() => {
          this.load();
        })
    },
    confimation(event, ...args) {
      this.$swal({
        title: 'Are you sure?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonColor: "#d14343",
        cancelButtonColor: '#010101'
      }).then(({isConfirmed}) => {
        if (!isConfirmed) {
          this.$swal({
            title: 'Confirmation',
            text: 'Ok, enter secret code',
            input: 'password',
            confirmButtonColor: "#d14343",
          }).then(async ({value}) => {
            if (value && (await compare(value, this.pass))) {
              this[event](...args);
            } else {
              await this.$swal({
                title: 'Wrong value'
              })
            }
          })
        }
      })
    },
    open() {
      this.active = true;
    },
  },
  mounted() {
    this.load()
  }
}
</script>

<style scoped>

</style>
