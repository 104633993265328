import Vue from "vue";
import {Pagination} from "@/utils/pagination";
import {get, post} from "@/services/api";
import VinSearchPatterns from "@/views/dashboard/vin-search-rules/VinSearchPatterns";
import {mapMutations} from "vuex";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default Vue.extend({
  name: 'VinSearch',
  components: {
    VinSearchPatterns
  },
  mixins: [show_alert_mixin],
  data: () => ({
    fields: [
      {key: 'vehicle_identification_no', label: 'VIN'},
      {key: 'make', label: 'Brand'},
      'model', 'model_description', 'engine_code', 'dsg_code', 'ecu_code',
      {key: 'vehicles_results', label: 'Vehicle(s) found'},
      {key: 'has_results', label: 'Found?'},
      'options'
    ],
    pagination: new Pagination(),
    query: ''
  }),
  methods: {
    ...mapMutations(['loaderManager']),
    load() {
      this.$store.commit('loaderManager', true);
      get(`vin-search?page=${this.pagination.currentPage}&limit=${this.pagination.perPage}&query=${this.query}`)
        .then(({data}) => {
          this.pagination.total = data.count;
          this.pagination.setTableData(data.data);
        }).finally(() => this.$store.commit('loaderManager', false))
    },
    paginate(page) {
      this.pagination.currentPage = page;
      this.load();
    },
    async verifyVin(vin){
      this.loaderManager(true)
      await post("site/find-by-vin",{vin: vin }, true)
      setTimeout(()=>{
        this.load()
        this.loaderManager(false)
        this.showAlertNotification("Process completed")
      },5000)
    }
  },
  mounted() {
    this.load();
  }
})
